.U0eYRq_wrapper {
  margin-bottom: 10px;
}

.RXVr6q_disabledForm {
  opacity: .5;
  pointer-events: none;
}

/*# sourceMappingURL=index.1291132f.css.map */
